// prod
// const config = {
//   REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : 'https://api.mylustrelife.com',
//   REACT_APP_MEDIA_URL : process.env.REACT_APP_MEDIA_URL ? process.env.REACT_APP_MEDIA_URL : 'https://d1emz11y0csqgi.cloudfront.net/',
//   REACT_APP_SORT_RECOMMENDATION : process.env.REACT_APP_SORT_RECOMMENDATION ? process.env.REACT_APP_SORT_RECOMMENDATION : '1',
//   PENDING_MSG_TIME_LIMIT: 60 * 1000,
// }

// dev
const config = {
  REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : 'https://api-dev.mylustrelife.com',
  REACT_APP_MEDIA_URL : process.env.REACT_APP_MEDIA_URL ? process.env.REACT_APP_MEDIA_URL : 'https://dfwdvqgofbm9o.cloudfront.net/',
  REACT_APP_SORT_RECOMMENDATION : process.env.REACT_APP_SORT_RECOMMENDATION ? process.env.REACT_APP_SORT_RECOMMENDATION : '1',
  PENDING_MSG_TIME_LIMIT: 60 * 1000,
}

export default config;